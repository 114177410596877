/* @flow */

import type { SortableProductList } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

export type AdventCalendarDoorLocked = {|
  +__typename: "AdventCalendarDoorLocked",
  day: number,
  image?: {
    x1: string,
    x2: string,
  },
|};

export type AdventCalendarDoorToday = {|
  +__typename: "AdventCalendarDoorToday",
  +day: number,
  +variant: ?string,
  category: {
    name: string,
    +url: string,
    metaTitle?: string,
    shortDescription: string,
    description: string,
    +products: SortableProductList,
    image?: {
      x1: string,
      x2: string,
    },
    bannerSmall?: {
      x1: string,
      x2: string,
    },
    bannerMedium?: {
      x1: string,
      x2: string,
    },
    bannerLarge?: {
      x1: string,
      x2: string,
    },
  },
|};

export type AdventCalendarDoorOpen = {|
  +__typename: "AdventCalendarDoorOpen",
  +day: number,
  +variant: ?string,
  category: {
    name: string,
    +url: string,
    metaTitle?: string,
    shortDescription: string,
    description: string,
    image?: {
      x1: string,
      x2: string,
    },
    bannerSmall?: {
      x1: string,
      x2: string,
    },
    bannerMedium?: {
      x1: string,
      x2: string,
    },
    bannerLarge?: {
      x1: string,
      x2: string,
    },
  },
|};

export type AdventCalendarDoor =
  AdventCalendarDoorOpen |
  AdventCalendarDoorToday |
  AdventCalendarDoorLocked;

export type AdventCalendar = $ReadOnlyArray<AdventCalendarDoor>;

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: AdventCalendar }
  | { state: "ERROR", error: string };

export type AdventCalendarResponse = Response<typeof ADVENT_CALENDAR_RESPONSE, ?AdventCalendar>;

export type AdventCalendarRequest = {
  tag: typeof ADVENT_CALENDAR_REQUEST,
  testDate?: string,
  password?: string,
};

export const adventCalendarActive = (date: Date, dateStr: string): boolean => {
  const timeZone = dateStr.slice(-6);
  const MIN_DATE: Date = new Date(`2021-12-01T00:00:00${timeZone}`);
  const MAX_DATE: Date = new Date(`2021-12-15T23:59:59${timeZone}`);

  return date >= MIN_DATE && date <= MAX_DATE;
};

export const ADVENT_CALENDAR_RESPONSE: "response/advent-calendar" = "response/advent-calendar";
export const ADVENT_CALENDAR_REQUEST: "request/advent-calendar" = "request/advent-calendar";

export const AdventCalendarModel: Model<
  Data,
  { testDate: ?string, password: ?string },
  AdventCalendarRequest | AdventCalendarResponse
> = {
  id: "advent-calendar",
  init: ({ testDate, password }: { testDate: ?string, password: ?string }) =>
    updateData(
      { state: "LOADING" },
      { tag: ADVENT_CALENDAR_REQUEST, testDate, password }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case ADVENT_CALENDAR_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
